import { useEffect, useState } from "react"

export const utils = {
    checkUrlForString : str => {
        return typeof window !== `undefined` ? window.location.href.indexOf(str) > -1 : null
    },
    getQueryString : typeof window !== `undefined` ? window.location.search : "",
    reloadPage: () => typeof window !== `undefined` ? window.location.reload() : null,
    getParams: (param) => {
        const queryString = typeof window !== `undefined` ? window.location.search : "";
        const urlParams = queryString ? new URLSearchParams(queryString) : null;
        return urlParams ? urlParams.get(param) : null;
    },
    getPagePath : () => {
        return typeof window !== 'undefined' ? window.location.href : '';
    },
    scrollToTop: () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    formatPhoneNumber: (str) => {
        //Filter only numbers from the input
        let cleaned = ('' + str).replace(/\D/g, '');
        
        //Check if the input is of correct length
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      
        if (match) {
          return '(' + match[1] + ')' + match[2] + '-' + match[3]
        };
      
        return null
    },
    getAverageOfArray: (arr) => {
        const sum = arr.reduce((a, b) => a + b, 0);
        const avg = (sum / arr.length) || 0;
        return avg;
    },
    getCenterOfCoords: (coords) => {
        const lats = coords.map(c => c.lat);
        const lngs =  coords.map(c => c.lng);
        const lat = utils.getAverageOfArray(lats);
        const lng =  utils.getAverageOfArray(lngs);
        return [lat,lng]
    },
    collectionHas: (a, b) => { //helper function (see below)
        for(var i = 0, len = a.length; i < len; i ++) {
            if(a[i] === b) return true;
        }
        return false
    },
    findParentBySelector: (elm, selector) => {
        var all = document.querySelectorAll(selector);
        var cur = elm.parentNode;
        while(cur && !utils.collectionHas(all, cur)) { //keep going up until you find a match
            cur = cur.parentNode; //go up
        }
        return cur; //will return null if not found
    },
    openLinkInNewWindow: (url) => typeof window !== `undefined` ? window.open(url, '_blank').focus() : null,
    isMobile: () => {
        return typeof window !== `undefined` ? window.innerWidth < 768 : null
    },
    isXLDeviceOrSmaller: () => {
        return typeof window !== `undefined` ? window.innerWidth < 1380 : null
    },
    activateModalFocusTrap: (el_modal) => {
        var focusableEls = el_modal.querySelectorAll('button, a, input');
        var firstFocusableEl = focusableEls[0];  
        var lastFocusableEl = focusableEls[focusableEls.length - 1];
        var KEYCODE_TAB = 9;
        
        el_modal.addEventListener('keydown', function(e) {
            var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);
        
            if (!isTabPressed) { 
                return; 
            }
            /* shift + tab */ 
            if ( e.shiftKey ){
                if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();
                    e.preventDefault();
                }
            } else {
                    /* tab */ 
                if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();
                    e.preventDefault();
                }
            }
        });
    },
    onWindowSizeChange: func => {
        window.addEventListener('resize', func)
    },
    // https://dev.to/reedbarger/how-to-create-a-usewindowsize-react-hook-2bcm
    useWindowSize: () => {
        const isSSR = typeof window === "undefined"
        const [windowSize, setWindowSize] = useState({
        width: isSSR ? 1200 : window.innerWidth,
        height: isSSR ? 800 : window.innerHeight,
        })
    
        const changeWindowSize = () => {
        setWindowSize({ width: window.innerWidth, height: window.innerHeight })
        }
    
        useEffect(() => {
        window.addEventListener("resize", changeWindowSize)
    
        return () => {
            window.removeEventListener("resize", changeWindowSize)
        }
        }, [])
    
        return windowSize
    },
    getKeyByValue: (object, value) => {
        return Object.keys(object).find(key => object[key] === value);
    },
    imgUrl: url => {
        return (process.env.GATSBY_ROOT_PREFIX || "") + url
    },
    isEmpty: str => {
        return /^\s*$/.test(str);
    },
    sanitizePath: path => {
        return path.slice(1, -1).replaceAll("/", "-");
    },
    getUniqueIdSuffix: path => {
        return path ? utils.sanitizePath(path) + "-page" : "";
    },
    getCookie : name => {
        var match = document.cookie.match(RegExp('(?:^|;\\s*)' + name + '=([^;]*)')); 
        return match ? match[1] : null;
    },
    modalDisablePageScroll: (show) => {
        if (typeof window !== `undefined`) {
            const el_body = document.querySelector('body');
            if (el_body) {
                if (show) {
                    el_body.style.top = `-${window.scrollY || window.pageYOffset}px`;
                    el_body.classList.add('open-modal');
                } else {
                    el_body.classList.remove('open-modal');
    
                    const scrollY = el_body.style.top;
                    el_body.style.top = '';
                    window.scrollTo(0, parseInt(scrollY || '0') * -1);
                }
            }
        }
    },
    triggerOneTrustPanel: () => {
        if ('OneTrust' in window) {
            window.OneTrust.ToggleInfoDisplay()
        }
    }
}


