import React, { useState } from "react"

const defaultState = {
    hcpExitModalShow: false,
    setHcpExitModalShow: () => {},

    emailHcpsModalShow: false,
    setEmailHcpsModalShow: () => {},

    streamswitchModalShow: false,
    setStreamswitchModalShow: () => {},

    stickyNavShow: false,
    setStickyNavShow: () => {},
}

const AppContext = React.createContext(defaultState)

const AppProvider = ({ children }) => {
    const [hcpExitModalShow, setHcpExitModalShow] = useState(defaultState.hcpExitModalShow)
    const [emailHcpsModalShow, setEmailHcpsModalShow] = useState(defaultState.emailHcpsModalShow)
    const [streamswitchModalShow, setStreamswitchModalShow] = useState(defaultState.streamswitchModalShow)
    const [stickyNavShow, setStickyNavShow] = useState(defaultState.stickyNavShow)

    const store = {
        hcpExitModalShow: hcpExitModalShow,
        setHcpExitModalShow: setHcpExitModalShow,
        
        emailHcpsModalShow: emailHcpsModalShow,
        setEmailHcpsModalShow: setEmailHcpsModalShow,

        streamswitchModalShow: streamswitchModalShow,
        setStreamswitchModalShow: setStreamswitchModalShow,

        stickyNavShow: stickyNavShow,
        setStickyNavShow: setStickyNavShow,
    }

    return <AppContext.Provider value={store}>{children}</AppContext.Provider>
}

export default AppContext

export { AppProvider }
