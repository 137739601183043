import React from 'react';
import { AppProvider } from '@src/context';
import '@styles/main.scss';
import { utils } from '@src/utils/utils';

export const wrapRootElement = ({ element }) => <AppProvider>{element}</AppProvider>;


const onInitialClientRender = () => {
    const isEDMode = utils.checkUrlForString('ed=1');
    const el_body = document.querySelector('body');

    if(isEDMode) {
        const el_mobile_splash = document.querySelector('.splash');
        if (el_mobile_splash) {
            el_mobile_splash.remove();
        }

        el_body.classList.add('ed')

        const isEDIsiTrayExpanded = utils.checkUrlForString("isi-tray-expanded");
        if (isEDIsiTrayExpanded) {
            el_body.classList.add('isi-tray-expanded')
        }

        // form errors
        const isEDModeError = utils.checkUrlForString("errors");
        const el_input_wrappers = document.querySelectorAll('.input-wrapper');
        if (isEDModeError && el_input_wrappers) {
            el_body.classList.add('show-form-error')
            el_input_wrappers.forEach(el => {
                el.classList.add("error");
            })
            if (utils.checkUrlForString("email-hcp-modal-open=true")) {
                // only want errors on the hcp modal form, not the forms underneath
                const el_search_form_input = document.querySelectorAll('#urologist-finder-form_results-page .input-wrapper');
                el_search_form_input.forEach(el => {
                    el.classList.remove("error");
                })
            }
        }

        const el_mobile_nav_container = document.querySelector(".mobile-nav-container");
        // mobile menu open and crop
        const isEDMobileMenu = utils.checkUrlForString("mobile-menu");
        if (isEDMobileMenu) {
            el_body.style.overflow = 'hidden';
            el_body.classList.add("mobile-menu-open");
            if (el_mobile_nav_container) {
                el_mobile_nav_container.style.zIndex = "4";
            }
        }
        // mobile menu open full
        const isEDMobileMenuFull = utils.checkUrlForString("mobile-menu-full");
        if (isEDMobileMenuFull) {
            el_body.style.overflow = 'hidden';
            el_body.classList.add("mobile-menu-full");
            el_body.classList.add("mobile-menu-open");
            if (el_mobile_nav_container) {
                el_mobile_nav_container.style.zIndex = "4";
            }
        }


        // desktop menu open dropdown
        const edDesktopMenuOpen = utils.getParams("desktop-menu");
        if (edDesktopMenuOpen && Number(edDesktopMenuOpen)) {
            const el_desktop_menu = document.querySelectorAll('span.mainnav-menu');
            const el_should_open = el_desktop_menu[ Number(edDesktopMenuOpen) - 1];
            if (el_should_open) {
                el_should_open.classList.add('open');
            }
        }
        
        // show extra state on signup form
        const edShowPatientType = utils.getParams("signup-patient-type");
        const el_treatment = document.querySelector("#treatment");
        const el_patient_type_treatment = document.querySelector(".patient-type-treatment");
        if (edShowPatientType) {
            el_treatment.checked = true;
            el_patient_type_treatment.classList.add("show");
        } 

        // arrows have issue on screenshots...
		const faqExpanded = utils.getParams("faq-expanded");
        if (faqExpanded) {
            el_body.classList.add("faq-expanded");
        }

        // show scroll modals fully
        const modalFull = utils.getParams("modal-full");
        if (modalFull) {
            el_body.classList.add("modal-full");
        }
        
    } else {
        el_body.classList.remove('ed')
    }

    // oh man. one trust banner on close would focus on the first element on the document which is the skip tp main content
    // have it reset so the ugly button doesnt show in your face without actually tabbing
    setTimeout(() => {
        const oneTrustCookieButton = document.getElementById('onetrust-accept-btn-handler');
        if (oneTrustCookieButton) {
            oneTrustCookieButton.addEventListener("click", () => {
                const skipToMain = document.querySelector('.skip-to-main');
                if (skipToMain) {
                    skipToMain.blur();
                }
                const el_top = document.getElementById('top');
                if (el_top) {
                    el_top.setAttribute("tabIndex", 0);
                    el_top.focus();
                    el_top.removeAttribute("tabIndex");
                }
            })
        }
    }, 1000);

}
export {
    onInitialClientRender
}